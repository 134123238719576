import React from 'react'
import SbEditable from 'storyblok-react'
// import config from '../../gatsby-config'
import renderBlok from '@renderBlok'
import { PageContextProvider } from '@context'
import { primaryBuildFolder } from '@variables/global-variables'
import findAndAssignH1 from '@helpers/find-and-assign-h1'
import get from 'lodash/get'

// const sbConfig = config.plugins.find(
//   ({ resolve }) => resolve === 'gatsby-source-storyblok'
// )

// Attempt to import config from gatsby-config causes build error
// Workaround exists involving placing this data in siteMetaData and querying it with useStaticQuery/graphql:
// https://github.com/gatsbyjs/gatsby/issues/30634
const sbConfig = {
  options: {
    resolveLinks: 'url',
    resolveRelations: [
      'topNav.logo',
      'galleryModule.galleryItems',
      'resourceReferences.resources',
      'eventReferences.events',
      'subNav.pages',
      'detailsPage.subNav',
      'page.subNav',
      'page.canonicalPage',
      'detailsPage.canonicalPage',
      'resourceDetail.canonicalPage',
    ],
  },
}

const pageComponentsWithH1s = {
  detailsPage: 'title',
  eventDetail: 'title',
  meetupTopic: 'topicName',
  resourceDetail: 'title',
}

const loadStoryblokBridge = function (cb) {
  let script = document.createElement('script')
  script.type = 'text/javascript'
  script.src = `//app.storyblok.com/f/storyblok-latest.js?t=${process.env.GATSBY_STORYBLOK_PREVIEW_KEY}`

  script.onload = cb
  document.getElementsByTagName('head')[0].appendChild(script)
}

const getParam = function (val) {
  var result = ''
  var tmp = []

  window.location.search
    .substr(1)
    .split('&')
    .forEach(function (item) {
      tmp = item.split('=')
      if (tmp[0] === val) {
        result = decodeURIComponent(tmp[1])
      }
    })

  return result
}
class StoryblokEntry extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      story: null,
      topNav: { content: {} },
      bottomNav: { content: {} },
      subNav: [],
      redirectModal: [],
      marketoFormModule: { content: {} },
      resourceForm: { content: {} },
      resourceStories: [],
      webinarsStories: [],
      eventsForm: { content: {} },
      eventsStories: [{ content: {} }],
      meetUpFormCtaModule: { content: {} },
      alternates: [],
      meetupDetail: [],
      isLocalizedStory: false,
    }
  }

  componentDidMount() {
    loadStoryblokBridge(() => {
      this.initStoryblokEvents()
    })
  }

  loadStory(payload) {
    const path = window.storyblok.getParam('path')
    window.storyblok.get(
      {
        slug: path,
        version: 'draft',
        resolve_links: sbConfig.options.resolveLinks,
        resolve_relations: sbConfig.options.resolveRelations || [],
      },
      (data) => {
        const isSingleton = data.story.full_slug.indexOf('_content-types') > -1
        this.setState({ story: data.story })
        !isSingleton && this.loadTopNav(data.story.lang)
        !isSingleton && this.loadBottomNav(data.story.lang)
        !isSingleton && this.loadSubNav(data.story.lang)
        !isSingleton && this.loadRedirectModal(data.story.lang)
        !isSingleton && this.loadMarketoFormModule(data.story.lang)
        !isSingleton && this.loadResourceForm(data.story.lang)
        !isSingleton && this.loadEventsForm(data.story.lang)
        !isSingleton && this.loadWistiaAutoplayModal(data.story.lang)
        !isSingleton && this.loadMeetUpsFormCtaModule(data.story.lang)
        this.loadResourceStories(data.story.lang)
        this.loadWebinarsStories(data.story.lang)
        this.loadEventsStories(data.story.lang)
        this.loadMeetupDetail(data.story.lang)
      }
    )
  }

  loadTopNav(lang) {
    window.storyblok.get(
      {
        slug: `${primaryBuildFolder}/_content-types/acc-top-nav-unified`,
        version: 'draft',
        resolve_links: sbConfig.options.resolveLinks,
        resolve_relations: sbConfig.options.resolveRelations || [],
      },
      (data) => {
        this.setState({ topNav: data.story })
      },
      (error) => console.log('Getting the nav failed:', error)
    )
  }

  loadBottomNav(lang) {
    window.storyblok.get(
      {
        slug: `${primaryBuildFolder}/_content-types/acc-bottom-nav`,
        version: 'draft',
        resolve_links: sbConfig.options.resolveLinks,
        resolve_relations: sbConfig.options.resolveRelations || [],
      },
      (data) => {
        this.setState({ bottomNav: data.story })
      },
      (error) => console.log('Getting the bottomNav failed:', error)
    )
  }

  loadSubNav(lang) {
    window.storyblok.getAll(
      {
        starts_with: `${primaryBuildFolder}/_content-types/products-sub-nav`,
        version: 'draft',
        resolve_links: sbConfig.options.resolveLinks,
        resolve_relations: sbConfig.options.resolveRelations || [],
      },
      (data) => {
        this.setState({ subNav: data.stories })
      },
      (error) => console.log('Getting the subNav failed:', error)
    )
  }

  loadRedirectModal(lang) {
    window.storyblok.getAll(
      {
        starts_with: `${primaryBuildFolder}/_content-types/plangrid-transition-modal`,
        version: 'draft',
        resolve_links: sbConfig.options.resolveLinks,
        resolve_relations: sbConfig.options.resolveRelations || [],
      },
      (data) => {
        this.setState({ redirectModal: data.stories[0].content })
      },
      (error) => console.log('Getting the redirectModal failed:', error)
    )
  }

  loadBuildOnForm(lang) {
    window.storyblok.getAll(
      {
        starts_with: `${primaryBuildFolder}/_content-types/form-modal`,
        version: 'draft',
        resolve_links: sbConfig.options.resolveLinks,
        resolve_relations: sbConfig.options.resolveRelations || [],
      },
      (data) => {
        this.setState({ formModal: data.stories[0].content })
      },
      (error) => console.log('Getting the buildOn form modal failed:', error)
    )
  }

  loadWistiaAutoplayModal(lang) {
    window.storyblok.getAll(
      {
        starts_with: 'us/_sandbox/dan/build-on-page-2',
        version: 'draft',
        resolve_relations: sbConfig.options.resolveRelations || [],
      },

      (data) => {
        this.setState({ modalOpen: true })
      },

      (error) => console.log('Error opening the wistia autoplay modal:', error)
    )
  }

  loadMarketoFormModule(lang) {
    window.storyblok.get(
      {
        slug: `us/_content-types/integrations-form`,
        version: 'draft',
        resolve_links: sbConfig.options.resolveLinks,
        resolve_relations: sbConfig.options.resolveRelations || [],
      },
      (data) => {
        this.setState({ marketoFormModule: data.story })
      },
      (error) => console.log('Getting the marketoFormModule failed:', error)
    )
  }

  loadResourceForm(lang) {
    window.storyblok.get(
      {
        slug: `us/_content-types/resource-form`,
        version: 'draft',
        resolve_links: sbConfig.options.resolveLinks,
        resolve_relations: sbConfig.options.resolveRelations || [],
      },
      (data) => {
        this.setState({ resourceForm: data.story })
      },
      (error) => console.log('Getting the resourceForm failed:', error)
    )
  }

  loadEventsForm(lang) {
    window.storyblok.get(
      {
        slug: `us/_content-types/events-form`,
        version: 'draft',
        resolve_links: sbConfig.options.resolveLinks,
        resolve_relations: sbConfig.options.resolveRelations || [],
      },
      (data) => {
        this.setState({ eventsForm: data.story })
      },
      (error) => console.log('Getting the eventsForm failed:', error)
    )
  }

  loadMeetupDetail(lang) {
    const meetUps = 'meetupDetail'
    window.storyblok.getAll(
      {
        starts_with: `us/meetups/`,
        version: 'draft',
        resolve_links: sbConfig.options.resolveLinks,
        resolve_relations: sbConfig.options.resolveRelations || [],
      },
      (data) => {
        const stories =
          data.stories &&
          data.stories
            .filter((eachStory) => eachStory.content.component === meetUps)
            .sort(function (firstObj, secondObj) {
              let dateFirstObj = new Date(firstObj.content.meetupDate)
              let dateSecondObj = new Date(secondObj.content.meetupDate)
              return dateFirstObj - dateSecondObj
            })
        this.setState({ meetupDetail: stories })
      },
      (error) => console.log('Getting the meetupDetail failed:', error)
    )
  }

  loadResourceStories(lang) {
    const resourceCenter = 'Resource Center'
    const per_page = 50
    window.storyblok.getAll(
      {
        starts_with: 'us/resources/',
        version: 'draft',
        per_page: per_page,
        resolve_links: sbConfig.options.resolveLinks,
        resolve_relations: sbConfig.options.resolveRelations || [],
      },
      (data) => {
        const stories =
          data.stories &&
          data.stories.filter((eachStory) => eachStory.name !== resourceCenter)
        this.setState({ resourceStories: stories })
      },
      (error) => console.log('Getting the resources failed:', error)
    )
  }

  loadWebinarsStories(lang) {
    const webinarCenter = 'Webinar Center'
    window.storyblok.getAll(
      {
        starts_with: 'us/webinars/',
        version: 'draft',
        resolve_links: sbConfig.options.resolveLinks,
        resolve_relations: sbConfig.options.resolveRelations || [],
      },

      (data) => {
        const stories =
          data.stories &&
          data.stories.filter((eachStory) => eachStory.name !== webinarCenter)
        this.setState({ webinarsStories: stories })
      },

      (error) => console.log('Getting the webinars failed:', error)
    )
  }

  loadEventsStories(lang) {
    const eventsCenter = 'Events Center'
    const per_page = 50
    window.storyblok.getAll(
      {
        starts_with: 'us/events',
        version: 'draft',
        per_page: per_page,
        resolve_links: sbConfig.options.resolveLinks,
        resolve_relations: sbConfig.options.resolveRelations || [],
      },
      (data) => {
        const stories =
          data.stories &&
          data.stories.filter((eachStory) => eachStory.name !== eventsCenter)
        this.setState({ eventsStories: stories })
      },
      (error) => console.log('Getting the events failed:', error)
    )
  }

  loadMeetUpsFormCtaModule(lang) {
    window.storyblok.get(
      {
        slug: `us/_content-types/meetups-form-modal`,
        version: 'draft',
        resolve_links: sbConfig.options.resolveLinks,
        resolve_relations: sbConfig.options.resolveRelations || [],
      },
      (data) => {
        this.setState({ meetUpFormCtaModule: data.story })
      },
      (error) =>
        console.log('Getting the loadMeetUpsFormCtaModule failed:', error)
    )
  }

  initStoryblokEvents() {
    this.loadStory({ storyId: getParam('path') })

    let sb = window.storyblok
    sb.on(['change', 'published'], (payload) => {
      this.loadStory(payload)
    })

    sb.on('input', (payload) => {
      if (this.state.story && payload.story.id === this.state.story.id) {
        payload.story.content = sb.addComments(
          payload.story.content,
          payload.story.id
        )
        this.setState({ story: payload.story })
      }
    })

    sb.pingEditor(() => {
      if (sb.inEditor) {
        sb.enterEditmode()
      }
    })
  }

  render() {
    const {
      resourceStories,
      webinarsStories,
      resourceForm,
      story,
      eventsForm,
      meetupDetail,
      eventsStories,
      subNav,
      meetUpFormCtaModule,
    } = this.state

    if (story == null) {
      return <div>No Story in Editor.js</div>
    }
    const isSingleton = story.full_slug.indexOf('_content-types') > -1
    let content = story.content
    let topNav = this.state.topNav.content
    let bottomNav = this.state.bottomNav.content
    const redirectModal = this.state.redirectModal
    const removeNavLogoLink = !!content && content.removeNavLogoLink
    const removeSubNav = !!content && content.removeSubNav
    const removeTopBanner = !!content && content.removeTopBanner
    const customTopBanner = !!content && content.customTopBanner
    const customNavLogin = !!content && content.customNavLogin
    const invertedNav = !!content && content.invertedNav
    const topNavPhone = !!content && content.topNavPhone
    const topNavCTA = !!content && content.topNavCTA
    const removeBottomNav = !!content && content.removeBottomNav
    const alternates = this.state.topNav.alternates
    const isLandingPage = !!content && content.isLandingPage

    const pageComponentHasH1 =
      !!content[pageComponentsWithH1s[story.content.component]]
    story.content.body = findAndAssignH1(story.content.body, pageComponentHasH1)

    const extractedMatchedSubNav =
      content.subNav &&
      subNav &&
      subNav.filter((subNav) => subNav.slug === content.subNav.slug)

    const formattedSubNavContent =
      extractedMatchedSubNav && get(extractedMatchedSubNav, '[0].content')

    return (
      <SbEditable content={content}>
        <div>
          <PageContextProvider
            isInEditor={true}
            resources={resourceStories}
            webinars={webinarsStories}
            eventsForm={eventsForm.content}
            events={eventsStories}
            full_slug={story.full_slug}
            first_published_at={story.first_published_at}
            meetUps={meetupDetail}
            meetUpFormCtaModule={meetUpFormCtaModule.content}
          >
            {!isSingleton &&
              renderBlok(topNav, {
                removeSubNav: removeSubNav,
                removeNavLogoLink: removeNavLogoLink,
                topNavPhone: topNavPhone,
                topNavCTA: topNavCTA,
                removeTopBanner: removeTopBanner,
                customTopBanner: customTopBanner,
                customNavLogin: customNavLogin,
                invertedNav: invertedNav,
                subNav: formattedSubNavContent,
                alternates: alternates,
                isLandingPage: isLandingPage,
              })}
            {renderBlok(content, {
              marketoForm: resourceForm.content,
              redirectModal: redirectModal,
            })}
            {!isSingleton && !removeBottomNav && renderBlok(bottomNav)}
          </PageContextProvider>
        </div>
      </SbEditable>
    )
  }
}

export default StoryblokEntry
